@tailwind base;

@tailwind components;

@tailwind utilities;

.center-div-on-screen {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.color-primary {
  color: #13ce66;
}

.bg-color-primary {
  background-color: #13ce66;
}

.ant-pagination {
  padding-right: 1rem;
  /* padding-left: 2rem; */
}

label {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

body {
  min-height: 100%;
}

#root {
  height: 100%;
}
