.cert-wrapper {
  /* margin: auto; */
  /* width: 90%; */
  /* padding: 0; */
  font-family: Arial, Helvetica, sans-serif;
  /* border-width: 1px; */
  /* border-color: grey; */
  /* border-style: solid; */
  /* border-radius: 10px; */
  /* resize: both; */
  /* overflow: auto; */
  background-color: rgb(234, 241, 243);
  /* background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%); */
  box-shadow: 0px 5px 5px 5px rgb(155, 153, 153);
}

/* h2 {
  font-family: sans-serif;
} */

.cert-h3 {
  font-family: Avenir;
  /* width: 90%; */
  /* margin: 10% auto auto auto; */
  /* padding: 2% 1% 2% 1%; */
  /* color: white; */
  box-shadow: 0px 5px 5px 5px grey;
  /* border-radius: 10px; */
  background-image: linear-gradient(
    109.6deg,
    rgba(48, 207, 208, 1) 11.2%,
    rgba(51, 8, 103, 1) 92.5%
  );
}

.cert-div-right {
  float: right;
  width: 37%;
  padding: 5% 5% 5% 0%;
  display: block;
  text-align: center;
  resize: both;
}

.cert-div-left {
  /* padding: 0% 5% 5% 0%; */
  /* width: 50%; */
  float: left;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.person-table td {
  text-align: left;
  padding: 0% 0% 10% 10%;
}

.img-footer {
  width: 125px;
  height: auto;
  align-content: center;
}

.img-profile {
  min-width: 50%;
  max-width: 80%;
  height: auto;
  /* align-content: center; */
}

.bio-labels {
  /* padding: 5% 0% 0% 10%; */
  /* width: 200px; */
  display: inline-block;
  font-size: 10px;
  color: rgb(28, 20, 99);
}

.value-labels {
  /* padding: 1% 0% 0% 10%; */
  /* width: 200px; */
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  color: rgb(23, 23, 31);
}

.sub-labels {
  /* padding: 2% 0% 0% 10%; */
  /* width: 200px; */
  display: inline-block;
  font-size: 10px;
  color: rgb(28, 20, 99);
}

/* table {
  font-size: 12px;
} */

.right-table {
  padding: 10% 10% 10% 10%;
}

/* label {
  font-size: 10px;
  font-family: Avenir;
} */

.date-label {
  padding-left: 5%;
  font-size: 12px;
  font-weight: normal;
}

.verified-div {
  margin-top: 45%;
  padding: 5% 10% 5% 10%;
  border-color: #33cc33;
  border-width: 1px;
  border-style: none;
  border-radius: 5px;
  background-color: #33cc33;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.issuedate-div {
  padding: 5% 10% 5% 10%;
  font-family: Arial, Helvetica, sans-serif;
}

.logo-div {
  width: 90%;
  margin: auto;
}

.logo-table {
  margin-left: auto;
  margin-right: auto;
}

.copy-label {
  font-family: sans-serif;
  font-size: 10px;
  color: darkgrey;
}

.issue-label {
  font-weight: bold;
  font-size: 11px;
  color: rgb(143, 142, 142);
}

@media only screen and (min-width: 768px) {
  /* .cert-wrapper {
     width: 70%; 
     margin: auto; 
  } */
  /* .cert-h3 {
     width: 70%; 
     margin: auto; 
     padding: 1%; 
  } */
  .img-profile {
    min-width: 40%;
    max-width: 60%;
    height: auto;
    /* align-content: center; */
  }
  .verified-div {
    margin-top: 30%;
    padding: 5%;
    border-color: #33cc33;
    border-width: 1px;
    border-style: none;
    border-radius: 5px;
    background-color: #33cc33;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
  .issue-label {
    font-weight: bold;
    font-size: 14px;
    color: rgb(143, 142, 142);
  }
  .copy-label {
    font-size: 12px;
    color: darkgrey;
  }

  .bio-labels {
    display: inline-block;
    font-size: 12px;
    color: rgb(28, 20, 99);
  }

  .value-labels {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: rgb(23, 23, 31);
  }

  .sub-labels {
    display: inline-block;
    font-size: 12px;
    color: rgb(28, 20, 99);
  }
}
